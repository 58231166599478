import { Col, Row, Typography } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { RowDetail } from 'src/components/common';
import {
  checkIsProductFinished,
  renderNoukiDisplay,
  renderSize,
  renderWeight,
  isNokiAirline,
  getDeliveryDateExternal,
} from 'src/utils/helper';
import useQueryUrl from 'src/hooks/useQueryUrl';
import { NumberFormatter } from 'src/utils/number';
import { getDetailOrderString } from 'src/utils/quotation';

type Props = {
  item: Response.EstimatePrice['item'];
  queryTaxes?: Response.TaxesResponse['items'];
};

const ExpandedRowRender: React.FC<Props> = ({ item, queryTaxes }) => {
  const { t } = useTranslation();
  const params = useQueryUrl<SearchParams.EstimatePriceSearch>();

  const isProductFinished = checkIsProductFinished(
    params?.hinshL3Cd?.toString(),
  );

  return (
    <div className="table_item">
      <Row>
        <Col span={16}>
          <RowDetail
            title={t('pricing_page.estimate_results.product_name')}
            value={item?.hakudoShhnm}
            layout={{
              titleCol: { span: 4 },
              valueCol: { span: 20 },
            }}
            rowClassName="table_item_row"
            titleClassName="col_with_border"
            valueClassName="col_with_border"
          />
        </Col>
        <Col span={8}>
          <RowDetail
            title={t('pricing_page.estimate_results.weight')}
            value={renderWeight(item?.henkanJury, item?.jury)}
            layout={{
              titleCol: { span: 9 },
              valueCol: { span: 15 },
            }}
            rowClassName="table_item_row"
            titleClassName="col_with_border col_border_left_phone"
            valueClassName="col_with_border"
          />
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <RowDetail
            title={t('pricing_page.estimate_results.size')}
            value={
              !isProductFinished ? renderSize(item, item?.setdnKakoCd) : ''
            }
            layout={{
              titleCol: { span: 4 },
              valueCol: { span: 20 },
            }}
            rowClassName="table_item_row"
            titleClassName="col_with_border"
            valueClassName="col_with_border"
          />
        </Col>
        <Col span={8}>
          <RowDetail
            title={t('pricing_page.estimate_results.order_closing_time')}
            value={item?.taigaiSimeJikok}
            layout={{
              titleCol: { span: 9 },
              valueCol: { span: 15 },
            }}
            rowClassName="table_item_row"
            titleClassName="col_with_border col_border_left_phone"
            valueClassName="col_with_border col_with_border_hight_light"
          />
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <RowDetail
            title={t('pricing_page.estimate_results.cutting_process')}
            value={item?.setdnKako}
            layout={{
              titleCol: { span: 4 },
              valueCol: { span: 20 },
            }}
            rowClassName={classNames('table_item_row', {
              machining_row: isNokiAirline(item?.nokiExternal),
            })}
            titleClassName="col_with_border"
            valueClassName="col_with_border"
          />
        </Col>
        <Col span={8}>
          <RowDetail
            title={t('pricing_page.estimate_results.delivery_date')}
            value={
              item?.noki !== 'EXTERNAL'
                ? item?.noki
                : isNokiAirline(item?.nokiExternal)
                ? renderNoukiDisplay(item?.noukiDisplay)
                : t('order_page.delivery_method.surface', {
                    0: getDeliveryDateExternal(item?.noukiDisplay)?.byShip,
                  })
            }
            layout={{
              titleCol: { span: 9 },
              valueCol: { span: 15 },
            }}
            rowClassName="table_item_row"
            titleClassName="col_with_border col_border_left_phone"
            valueClassName="col_with_border col_hight_light value_break_line"
          />
        </Col>
      </Row>

      <Row>
        <Col span={16}>
          <RowDetail
            title={t('pricing_page.estimate_results.delivery_destination')}
            value={item?.nonyusm}
            layout={{
              titleCol: { span: 4 },
              valueCol: { span: 20 },
            }}
            rowClassName="table_item_row"
            titleClassName="col_with_border"
            valueClassName="col_with_border"
          />
        </Col>
        <Col span={8}>
          <RowDetail
            title={t('pricing_page.estimate_results.stock_status')}
            value={
              item?.zaikMeyasu !== 'EXTERNAL'
                ? item?.zaikMeyasu
                : t('pricing_page.estimate_results.stock_status_external')
            }
            layout={{
              titleCol: { span: 9 },
              valueCol: { span: 15 },
            }}
            rowClassName="table_item_row"
            titleClassName="col_with_border col_border_left_phone"
            valueClassName="col_with_border col_hight_light"
          />
        </Col>
      </Row>

      <Row>
        <Col span={16}>
          <RowDetail
            title={t('pricing_page.estimate_results.detail')}
            value={getDetailOrderString(
              item?.kyaksChmnNo,
              item?.opt,
              item?.optc091,
              item?.biko,
              isProductFinished,
            )}
            layout={{
              titleCol: { span: 4 },
              valueCol: { span: 20 },
            }}
            rowClassName="table_item_row"
            titleClassName="col_with_border col_two_row"
            valueClassName="col_with_border col_two_row"
          />
        </Col>
        <Col span={8}>
          <Row className="mb-10 table_item_row">
            <Col span={24}>
              <Row className="mb-10 table_item_sub_row">
                <Col span={9} className="col_with_border col_border_left_phone">
                  <Typography.Title level={5}>
                    {t('pricing_page.estimate_results.price_excluding_tax')}
                  </Typography.Title>
                </Col>
                <Col
                  span={7}
                  className="col_with_border col_hight_light col_align_right"
                >
                  <Typography.Text className="text_price">
                    {NumberFormatter.format(
                      parseFloat(item?.unitPrice?.split(' ')[0]),
                      2,
                    )}
                  </Typography.Text>
                </Col>
                <Col span={8} className="col_with_border">
                  <Typography.Text>
                    {item?.unitPrice?.split(' ')[1]}
                  </Typography.Text>
                </Col>
                <Col span={9} className="col_with_border col_border_left_phone">
                  <Typography.Title level={5}>
                    {t('pricing_page.estimate_results.total_excluding_tax')}
                  </Typography.Title>
                </Col>
                <Col
                  span={7}
                  className="col_with_border col_hight_light col_align_right"
                >
                  <Typography.Text className="text_price">
                    {NumberFormatter.format(
                      parseFloat(item?.totalPrice?.split(' ')[0]),
                      2,
                    )}
                  </Typography.Text>
                </Col>
                <Col span={8} className="col_with_border">
                  <Typography.Text>
                    ({item?.totalPrice?.split(' ')[1]})
                  </Typography.Text>
                </Col>
                {queryTaxes?.map((tax, index) => (
                  <React.Fragment key={index}>
                    <Col
                      span={9}
                      className="col_with_border col_border_left_phone"
                    >
                      <Typography.Title level={5}>
                        {tax?.taxName}
                      </Typography.Title>
                    </Col>
                    <Col
                      span={7}
                      className="col_with_border col_hight_light col_align_right"
                    >
                      <Typography.Text className="text_price">
                        {tax?.zeikin}
                      </Typography.Text>
                    </Col>
                    <Col span={8} className="col_with_border">
                      <Typography.Text>({tax?.sogakuTani})</Typography.Text>
                    </Col>
                  </React.Fragment>
                ))}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ExpandedRowRender;
