import { Col, Divider, Row, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ItemSubLabel from 'src/containers/Quotation/ItemSubLabel';
import { formatDimensions } from 'src/utils/helper';
import { getMillSheet } from 'src/utils/quotation';

export type WebMitrDetailProps = {
  item: Response.QuotationEntity;
  type?: string;
  screen?: 'R1005' | 'R1006';
  currentCurrency?: string;
  showPriceIncludingTax?: boolean;
};

const WebMitrDetail: React.FC<WebMitrDetailProps> = (
  props: WebMitrDetailProps,
) => {
  const { item, type } = props;
  const { t } = useTranslation();
  const blue = type === 'expired' ? 'default' : 'blue';
  const green = type === 'expired' ? 'default' : 'green';
  return (
    <React.Fragment>
      <Divider type="horizontal" className="item_data_divider" />
      <ItemSubLabel
        label={t('quotation_page.dimensions')}
        value={formatDimensions(item, item?.setdnKakoCd)}
        color={blue}
      />
      <ItemSubLabel
        label={t('quotation_page.processing')}
        value={item.setdnkako}
        color={blue}
      />
      {item.kakoKosa && (
        <ItemSubLabel
          label={t('quotation_page.machining_tolerance')}
          value={item.kakoKosa}
          color={blue}
        />
      )}
      {item.optSiteiNaiyo012 && (
        <ItemSubLabel
          label={t('quotation_page.full_circumference_chamfer')}
          value={item.optSiteiNaiyo012}
          color={blue}
        />
      )}
      {item.optc013A && item.optc013B && item.optc013C && item.optc013D && (
        <ItemSubLabel
          label={t('quotation_page.corner_processing')}
          value={`${item.optc013A}x${item.optc013B}x${item.optc013C}x${item.optc013D}`}
          color={blue}
        />
      )}
      {item.optc025 && (
        <ItemSubLabel
          label={t('quotation_page.roll_eyes')}
          value={item.optc025}
          color={blue}
        />
      )}
      <ItemSubLabel
        label={t('quotation_page.mill_sheet')}
        value={item?.shiagsnpoHyojiMojirets && getMillSheet(item.optc091)}
        color={green}
      />
      <Divider type="horizontal" className="item_data_divider-bottom" />
      <Row className="mb-10" justify="space-between">
        <Col xs={24} md={5} className="info">
          <Row className="info_row">
            <Typography.Title level={5}>
              {t('quotation_page.quotation_registration_date')}&nbsp;&nbsp;
            </Typography.Title>
            <Typography.Text>
              {item.webMitrTrokub?.slice(0, 10)}
            </Typography.Text>
          </Row>
        </Col>
        <Col xs={24} md={5} className="info">
          <Row className="info_row">
            <Typography.Title level={5}>
              {t('quotation_page.quotation_expiration_date')}&nbsp;&nbsp;
            </Typography.Title>
            <Typography.Text>
              {item.mitrYukoKigenb?.slice(0, 10)}
            </Typography.Text>
          </Row>
        </Col>
        <Col xs={24} md={5} className="info">
          <Row className="info_row">
            <Typography.Title level={5}>
              {t('quotation_page.quote_number')}&nbsp;&nbsp;
            </Typography.Title>
            <Typography.Text>{item.webMitrNo}</Typography.Text>
          </Row>
        </Col>
        <Col xs={24} md={4} className="info">
          <Row className="info_row">
            <Typography.Title level={5}>
              {t('quotation_page.delivery_address')}&nbsp;&nbsp;
            </Typography.Title>
            <Typography.Text>{item.kokyakum}</Typography.Text>
          </Row>
        </Col>
        <Col xs={24} md={4} className="info">
          <Row className="info_row">
            <Typography.Title level={5}>
              {t('quotation_page.person_in_charge')}&nbsp;&nbsp;
            </Typography.Title>
            <Typography.Text>{item.kokyakuTanto}</Typography.Text>
          </Row>
        </Col>
      </Row>
      <Divider type="horizontal" className="item_data_divider-bottom" />
      <Row className="mb-10">
        <Col xs={24} md={12} className="info">
          <Row className="info_row">
            <Typography.Title level={5}>
              {t('quotation_page.your_order_number') + ':'}&nbsp;&nbsp;
            </Typography.Title>
            <Typography.Text>{item.kyaksChmnNo}</Typography.Text>
          </Row>
        </Col>
        <Col xs={24} md={12} className="info">
          <Row className="info_row">
            <Typography.Title level={5}>
              {t('quotation_page.remarks')}&nbsp;&nbsp;
            </Typography.Title>
            <Typography.Text>{item.biko}</Typography.Text>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default WebMitrDetail;
