import React from 'react';
import { Row } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import BgTopCaculate from 'src/assets/images/bg-top-1.png';
import BgTopSearch from 'src/assets/images/bg-top-2.png';
import CaculateIcon from 'src/assets/images/caculate-icon.png';
import SearchProductIcon from 'src/assets/images/search-product-icon.png';

import { PATH as PATH_PRICING } from 'src/pages/pricing';
import { PATH as PATH_QUOTATION } from 'src/pages/quotation';
import { PATH_2 as PATH_PRODUCT } from 'src/pages/productDetails';
import { CATEGORIES_ID, SAMPLE_PRODUCT_ID } from 'src/constants/app';

const MenuNavigation: React.FC = () => {
  const { t } = useTranslation();
  const env = process.env.REACT_APP_SAMPLE_BANNER_SHOW;

  return (
    <Row className="caculate-menu">
      <Link to={PATH_PRICING}>
        <div
          className="caculate-menu-item caculate-menu-item-caculate"
          style={{ backgroundImage: `url(${BgTopCaculate})` }}
        >
          <div>
            <img src={CaculateIcon} alt="" />
          </div>
          <div className="content">
            <span className="text-title">
              {t('top_page.top_navigate.calculate_title')}
            </span>
            <span className="text-des">
              {t('top_page.top_navigate.calculate_des')}
            </span>
          </div>
        </div>
      </Link>
      <Link to={PATH_QUOTATION}>
        <div
          className="caculate-menu-item caculate-menu-item-search"
          style={{ backgroundImage: `url(${BgTopSearch})` }}
        >
          <div>
            <img src={SearchProductIcon} alt="" />
          </div>
          <div className="content">
            <span className="text-title">
              {t('top_page.top_navigate.search_title')}
            </span>
            <span className="text-des">
              {t('top_page.top_navigate.search_des')}
            </span>
          </div>
        </div>
      </Link>
      {env && (
        <>
          <Link
            to={`${PATH_PRICING}${PATH_PRODUCT}?hinshL3Cd=${CATEGORIES_ID.ALUMNINUM}&shhnCd=${SAMPLE_PRODUCT_ID}`}
          >
            <div
              className="caculate-menu-item caculate-menu-item-search"
              style={{ backgroundImage: `url(${BgTopSearch})` }}
            >
              <div>
                <img src={SearchProductIcon} alt="" />
              </div>
              <div className="content">
                <span className="text-title">
                  {t('top_page.top_navigate.sample_banner')}①
                </span>
              </div>
            </div>
          </Link>
        </>
      )}
    </Row>
  );
};

export default MenuNavigation;
